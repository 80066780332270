<template>
  <div class="create-page" v-if="!steps.length">
    <CForm
      v-on:submit.prevent="createSingleStep"
      novalidate
      class="create-page__form"
    >
      <FormBuilder
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
      />
    </CForm>
  </div>
  <div class="create-page" v-else>
    <div class="create-page__header">
      <CRow class="mr-0 ml-0">
        <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
          <div class="zq-page-title-wrapper d-flex">
            <h3 class="zq-page-title">{{ texts.createPage.title }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
            :currentStep="currentStep"
            :totalStep="totalStep"
            :finishAction="createEntity"
            @updateCurrentStep="updateCurrentStep"
            @next-step="nextStep"
          />
        </CCol>
      </CRow>
      <WizardHeader
        :currentStep="currentStep"
        :steps="steps"
        @updateCurrentStep="updateCurrentStep"
      />
    </div>
    <div class="content">
      <FormBuilder
        v-if="currentStep === 0"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
        :isCreateHeader="false"
        :isSettingsStep="true"
      />
      <CreateRules
        v-if="steps.find(step => step.key === 'rules') && currentStep === getStepNumber('rules')"
        context="webhook"
        @updateRulesData="updateRulesData"
        :stepNumber="getStepNumber('rules') + 1"
      />
      <CreateDependantOn
        v-if="steps.find(step => step.key === 'dependantOn') && currentStep === getStepNumber('dependantOn')"
        @updateDependantOnData="updateDependantOnData"
        @resetDependantValidation="resetDependantValidation"
        @updateCurrentTypes="updateDependantOnCurrentTypes"
        :isValid="dependantOnDataValidate"
        :dependantOnData="dependantOnData"
        :stepNumber="getStepNumber('dependantOn') + 1"
      />
      <CreateScheduling
        v-if="steps.find(step => step.key === 'scheduling') && currentStep === getStepNumber('scheduling')"
        @updateSchedulingData="updateSchedulingData"
        @resetValidate="resetSchedulingValidate"
        :schedulingData="schedulingData"
        :name="settingsData.name"
        :isFrom="schedulingDataValid_startDate"
        :isScheduleType="schedulingDataValid_scheduleType"
        :isEvery="schedulingDataValid_every"
        :isLimit="schedulingDataValid_scheduleOccurrencesLimit"
      />
      <CreateTranslations
        v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations')"
        :entityData="settingsData"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        @updated="updateTranslationsData"
        :stepNumber="getStepNumber('translations') + 1"
      />
      <CreateRewards
        v-if="steps.find(step => step.key === 'rewards') && currentStep === getStepNumber('rewards')"
        @updateRewardData="updateRewardData"
      />
      <SummaryStep
        v-if="currentStep === getStepNumber('summary')"
        :settingsData="settingsData"
        :rulesData="rulesData"
        :dependantOnData="steps.find(step => step.key === 'dependantOn') ? dependantOnData : {}"
        :schedulingData="steps.find(step => step.key === 'scheduling') ? schedulingData : {}"
        :rewardsData="rewardsData"
        :translationsData="translationsData"
        :stepNumber="getStepNumber('summary') + 1"
        :model="model"
        :translatableFields="translatableFields"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateRules from '@/shared/components/supportModels/rules/CreateRules';
import CreateDependantOn from '@/shared/components/supportModels/dependantOn/CreateDependantOn';
import CreateScheduling from '@/shared/components/supportModels/scheduling/CreateScheduling';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import SummaryStep from '@/shared/components/steps/SummaryStep';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { webhooks } from '@/config/descriptions/webhooks.json';
import { webhooksTexts } from '@/config/pageTexts/webhooks.json';
import webhookFields from '@/generated/ziqni/store/modules/webhooks/fields';
import { dateUTC } from '@/utils/dateUTC';
import { cloneDeep } from 'lodash';


export default {
  name: 'CreateWebhook',
  components: {
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateRules,
    CreateDependantOn,
    CreateScheduling,
    CreateRewards,
    SummaryStep,
  },
  data() {
    return {
      model: 'webhooks',
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...webhooks.create,
      },
      texts: {
        ...webhooksTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        scheduleOccurrencesLimit: null,
        scheduleType: '',
        constraints: [],
        endDate: '',
        startDate: dateUTC(),
        every: [],
      },
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      transformedRewards: [],
      rulesData: {},
    };
  },
  computed: {
    ...mapGetters('webhooks', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('webhooks', ['handleCreateWebhooks']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      this.formList = fieldHelpers.prepareCreateFormList(
        webhookFields,
        webhooksTexts.createPage,
        webhooks.create
      );

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }

        if (field.required) {
          this.requiredFields.push(field.key);
        }
      })

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
            this.requiredFields.scheduling.push('scheduleType')
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);
        let stepNumber = 1

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: stepNumber,
          });
          this.stepKeys.push(step.key);

          stepNumber++;
        })

        this.translatableFields = webhookFields.baseFields.translatableFields;

        this.lastStep.step = stepNumber;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
    },
    getInvalidFields() {
      let result = [];

      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '' || (Array.isArray(this.settingsData[key]) && !this.settingsData[key].length))) {
          result.push(key);
        }
      }
      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    updateRulesData(val) {
      this.rulesData = val;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (!invalidFieldElement.classList.contains('zq--form-row')) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getRules() {
      let rules = [];
      for (const action in this.rulesData) {
        let ruleObject = {};

        ruleObject.entityId = '';
        ruleObject.action = action;
        ruleObject.context = 'webhook';
        ruleObject.rules = this.rulesData[action];
        ruleObject.rules.type = 'condition';
        ruleObject.rules.lineNumber = 1;

        rules.push(ruleObject);
      }

      return rules
    },
    createSingleStep() {
      let invalidFields = this.getInvalidFields();
      if (!invalidFields.length) {
        this.createEntity();
      } else {
        this.setInvalidFields(invalidFields)
      }
    },
    createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }
      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
      }
      if (Object.keys(this.rulesData).length) {
        formData.rules = this.getRules();
      }
      if (Object.keys(this.dependantOnData).length && this.dependantOnData.formData) {
        let isDependantOn = false;
        for (let key in this.dependantOnData.formData.dependantOn) {
          if (this.dependantOnData.formData.dependantOn[key].length) {
            isDependantOn = true;
          }
        }
        if (isDependantOn) {
          formData.dependantOn = {};
          formData.dependantOn.dependantOn = this.dependantOnData.formData.dependantOn;
          formData.dependantOn.entityType = 'member';
        }
      }

      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      this.handleCreateWebhooks({createWebhookRequest: formData})
        .then(data => {
          if (data.length) {
            this.$router.push({
              name: 'PreviewWebhook',
              params: {
                id: data[0].id,
              }
            })
          } else {
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
